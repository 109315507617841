<template>
    <div>
            <section class="breadcrumb-outer text-center bg-orange">
        <div class="container">
                <h2 data-aos="zoom-in-up">{{ $t('Header.Documents.Center') }}</h2>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                   <div data-aos="flip-up" class="doc" style="display: flex">
                         <img data-src="/assets/images/Home/docu.png" alt="image" style="height: 250px;border-radius: 50px;" class="lazyload blur-up">
                         <div style="padding: 50px 20px; text-align: center;">
                             <h2 style="font-size: 16px">{{ $t('presiru') }}</h2>
                            <div class="rt-cartprice-wrapper text-center">
                                <div class="button">
                                    <a href="https://drive.google.com/file/d/1JP_BVcNERrMAYXIUM_BZTkRtjxowmpVq/view?usp=sharing" target="_blank" rel="noopener noreferrer" class="btn-blog">{{ $t('Main.korish') }}</a>
                                </div>
                            </div>
                         </div>
                   </div>
               </div>
                <div class="col-12 col-md-6">
                   <div data-aos="flip-up" class="doc" style="display: flex">
                        <img data-src="/assets/images/Home/docu.png" alt="image"  style="height: 250px;border-radius: 50px;" class="lazyload blur-up">
                         <div style="padding: 50px 20px; text-align: center;">
                             <h2 style="font-size: 16px">{{ $t('presiuz') }}</h2>
                            <div class="rt-cartprice-wrapper text-center">
                                <div class="button">
                                    <a href="https://docs.google.com/document/d/10wm35SnJ59J37fjmsVxBPpDJTUXYyNQtUBJEFgx18kQ/edit?usp=sharing" target="_blank" rel="noopener noreferrer" class="btn-blog">{{ $t('Main.korish') }}</a>
                                </div>
                            </div>
                         </div>
                   </div>
               </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
export default {
              metaInfo: {
    title: "Hujjatlar",
    titleTemplate: 'Hujjatlar'
  },
}
</script>
<style scoped>
.bg-orange{
    margin: 0;
    background-color: #3498DB !important;
    background-image: none;
}
.breadcrumb-outer {
    padding: 40px 0 !important;
    position: relative;
}
.breadcrumb-outer h2{
    padding: 70px 0 15px !important;
}
.d-flex{
    display: flex;
    flex-direction: column;
}
.doc{
    display: flex;
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    overflow: hidden;
    height: inherit;
    box-shadow: 0 0 15px #cccccc30;
    border: 5px solid #fff;
    padding: 30px 0px 30px 70px;
}
.doc :hover{
    transition: all ease-in-out 0.5s;
    transform: translateY(-5px);
}
img{
    width: 220px;
}
@media (max-width: 500px){
    .doc{
        display: flex;
        flex-direction: column;
        padding: 30px 40px 30px 40px;
    }
      img{
      width: 100%  !important;
  }
}
@media(max-width: 400px){
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
  img{
      width: 100%  !important;
  }
}
</style>